import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import {
  AuthTokenResponse,
  ItemAnnotation,
  Loadout,
  ProfileResponse,
  TagUpdate,
  TagValue
} from '@destinyitemmanager/dim-api-types';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '@Services/core.service';

@Injectable({
  providedIn: 'root'
})
export class DimSyncService {

  profile: ProfileResponse;
  busy: boolean;
  loading: boolean;
  dimSyncState: {
    accessToken: string;
    accessTokenExpirationDate: number;
  };
  DIMTags: TagValue[] = ['favorite', 'keep', 'infuse', 'junk', 'archive'];
  tagIcons: {favorite: IconProp, keep: IconProp, infuse: IconProp, junk: IconProp, archive: IconProp} = {
    favorite: ['fas', 'heart'],
    keep: ['fas', 'tag'],
    infuse: ['fas', 'bolt'],
    junk: ['fas', 'ban'],
    archive: ['fas', 'archive'],
  };
  autoRefresh$: Subject<void>;
  autoRefreshSubscription: Subscription;

  get unauthenticatedHeaders() {
    return {
      'x-api-key': environment.dimSyncApiKey
    };
  }

  get authenticatedHeaders() {
    return {
      Authorization: `Bearer ${this.dimSyncState?.accessToken}`,
      ...this.unauthenticatedHeaders
    };
  }

  constructor(private http: HttpClient, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private coreService: CoreService) {
    this.getStoredCredentials();
    this.auth.authChanged.subscribe((authenticated) => {
      this.handleAuthChanged(authenticated);
    });
  }

  getStoredCredentials() {
    const dimSyncState = this.coreService.getLocalStorageItem('dim-sync-state');
    if (dimSyncState) {
      try {
        this.dimSyncState = JSON.parse(dimSyncState);
      } catch (err) {
        console.error('Could not parse dimSyncState');
        this.coreService.removeLocalStorageItem('dim-sync-state');
      }
    }
  }

  handleAuthChanged(authenticated: boolean) {
    if (this.autoRefreshSubscription) {
      this.autoRefreshSubscription.unsubscribe();
    }
    if (authenticated === true) {
      this.loadDimSyncProfile();
    } else {
      this.profile = null;
    }
  }

  getDimSyncToken(): Observable<boolean> {
    this.getStoredCredentials();
    if (!this.dimSyncState || Date.now() >= this.dimSyncState.accessTokenExpirationDate) {
      return this.http.post<AuthTokenResponse>(environment.dimSyncHost + '/auth/token', {
        membershipId: this.auth.membershipData.bungieNetUser.membershipId,
        bungieAccessToken: this.auth.token
      }, { headers: this.unauthenticatedHeaders }).pipe(
        map((response) => {
          this.dimSyncState = {
            accessToken: response.accessToken,
            accessTokenExpirationDate: Date.now() + response.expiresInSeconds * 1000
          };
          this.coreService.setLocalStorageItem('dim-sync-state', JSON.stringify(this.dimSyncState));
          return true;
        }),
      );
    } else {
      return of(true);
    }
  }

  loadDimSyncProfile(notifier?: Subject<void>) {
    if (this.loading) { return; }
    this.loading = true;
    this.getDimSyncToken().pipe(
      mergeMap(() => {
        return this.http.get(environment.dimSyncHost + '/profile', {
          headers: this.authenticatedHeaders,
          params: {
            platformMembershipId: this.auth.currentMemberShip.membershipId,
            destinyVersion: '2',
            components: 'settings,loadouts,tags'
          }
        });
      }),
    ).subscribe((response: ProfileResponse) => {
      this.profile = response;
      if (notifier) {
        notifier.next();
        notifier.complete();
      }
      if (!this.profile) {
        this.toastr.success(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.READY'));
      }
      this.loading = false;
    }, (err) => {
      if (this.profile) {
        this.toastr.error(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.ERROR'));
      }
      if (notifier) {
        notifier.error(err);
      }
      this.loading = false;
    });
  }

  refresh(): Observable<any> {
    if (!this.profile) {
      return of(undefined);
    }
    const notifier = new Subject<void>();
    this.loadDimSyncProfile(notifier);
    return notifier;
  }

  sendTagUpdates(updates: TagUpdate[]) {
    if (this.busy) { throw new Error('DIM Sync is busy'); }
    this.busy = true;
    return this.getDimSyncToken().pipe(
      mergeMap(() => {
        return this.http.post<Response>(environment.dimSyncHost + '/profile', {
          platformMembershipId: this.auth.currentMemberShip.membershipId,
          destinyVersion: 2,
          updates
        }, { headers: this.authenticatedHeaders });
      }),
      tap(() => {
        for (const update of updates) {
          const idx = this.profile.tags.findIndex((i) => update.payload.id === i.id);
          if (idx === -1) {
            this.profile.tags.push(update.payload);
            this.busy = false;
            return;
          }
          if (Object.prototype.hasOwnProperty.call(update.payload, 'notes')) {
            this.profile.tags[idx].notes = update.payload.notes;
          }
          if (Object.prototype.hasOwnProperty.call(update.payload, 'tag')) {
            this.profile.tags[idx].tag = update.payload.tag;
          }
        }
        this.busy = false;
      }),
      catchError((error) => {
        console.error(error);
        this.busy = false;
        return throwError(error);
      })
    );
  }

  getAnnotationsForItem(itemInstanceId: string): ItemAnnotation {
    if (this.profile?.tags?.length) {
      return this.profile.tags.find((item) => item.id === itemInstanceId);
    }
  }

  getTagIconForItem(itemInstanceId: string): IconProp {
    const entry = this.getAnnotationsForItem(itemInstanceId);
    if (entry?.tag) {
      return this.tagIcons[entry.tag];
    }
    return undefined;
  }

  getLoadoutsForItem(itemInstanceId: string): Loadout[] {
    if (this.profile?.loadouts) {
      return this.profile.loadouts.filter((loadout) => {
        return loadout.equipped.findIndex((i) => i.id === itemInstanceId) > -1;
      });
    }
  }

}
